import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable, Signal, signal } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, tap } from "rxjs";
import { LogoutResponse, RefreshTokenResponse, User } from "../interfaces";
import { Store } from "@ngrx/store";
import { AuthStateResponse, selectUser } from "../state";
import { Nullable } from "@eqn/data-types";
import { UserAuthSessions } from "../parameters/user-auth.enums";

@Injectable({
  providedIn: "root",
})
export class UserAuthService {
  private readonly _http = inject(HttpClient);
  private readonly _apiBase: string = environment.apiBaseUrl;
  private readonly _store = inject(Store<AuthStateResponse>);

  public get userData(): Signal<Nullable<User>> {
    const user = this._store.selectSignal(selectUser);
    return user()?.accessToken ? user : signal(this.userSession);
  }

  public getAuthorizationHeader(accessToken?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + (accessToken || this.userData()?.accessToken),
      }),
    };

    return httpOptions;
  }

  public loginUser(authToken: string): Observable<User> {
    const endpoint = `${this._apiBase}/auth/web/auth0`;

    return this._http.post<User>(endpoint, {}, this.getAuthorizationHeader(authToken)).pipe(
      tap({
        next: (res) => this.saveUserSession(res),
        error: () => this.removeUserSession(),
      })
    );
  }

  public logout(): Observable<LogoutResponse> {
    const endpoint = `${this._apiBase}/auth/logout`;

    return this._http
      .post<LogoutResponse>(endpoint, {}, this.getAuthorizationHeader())
      .pipe(tap(() => this.removeUserSession()));
  }

  public refreshToken(): Observable<RefreshTokenResponse> {
    const endpoint = `${this._apiBase}/auth/refresh-token`;

    return this._http.post<RefreshTokenResponse>(
      endpoint,
      {},
      this.getAuthorizationHeader(this.userData()?.refreshToken)
    );
  }

  public saveUserSession(authSession: User) {
    if (!authSession) return;
    sessionStorage.setItem(UserAuthSessions.USER_SESSION, JSON.stringify(authSession));
  }

  public removeUserSession() {
    if (this.userSession) {
      sessionStorage.removeItem(UserAuthSessions.USER_SESSION);
    }
  }

  public get userSession(): User {
    const userSession = sessionStorage.getItem(UserAuthSessions.USER_SESSION);
    return userSession ? JSON.parse(userSession) : undefined;
  }
}
