import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { User } from "../../interfaces";

export type GetLoginSuccessPropsType = { user: User };
export type GetLoginFailedPropsType = { error: unknown };

export const AuthApisActions = createActionGroup({
  source: "Auth/Apis",
  events: {
    authLogin: props<{ authToken: string }>(),
    authLoginSuccess: props<GetLoginSuccessPropsType>(),
    authLoginFailed: props<GetLoginFailedPropsType>(),
    authLogout: emptyProps(),
    authLogoutSuccess: emptyProps(),
    updateUser: props<GetLoginSuccessPropsType>(),
  },
});
