import { User } from "../interfaces";

export interface AuthStateResponse {
  isLoggedIn: boolean;
  user: User | null;
  error: unknown;
}

export const authInitialState = {
  isLoggedIn: false,
  user: null,
  error: null,
} as AuthStateResponse;
