import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { UserAuthService } from "../../services/user-auth.service";
import { catchError, map, of, switchMap, tap } from "rxjs";
import { AuthApisActions } from "../actions";
import { Store } from "@ngrx/store";
import { LoadingState } from "../../../store/loading/reducers";
import { LoadingActions } from "../../../store/loading/actions";
import { AuthService } from "@auth0/auth0-angular";
import { environment } from "../../../../environments/environment";

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApisActions.authLogin),
      switchMap(({ authToken }) => {
        this.store.dispatch(LoadingActions.startLoading());
        return this.userAuthService.loginUser(authToken).pipe(
          tap(() => this.store.dispatch(LoadingActions.stopLoading())),
          map((user) => AuthApisActions.authLoginSuccess({ user: user })),
          catchError((error) => {
            this.store.dispatch(LoadingActions.stopLoading());
            return of(AuthApisActions.authLoginFailed({ error }));
          })
        );
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApisActions.authLogout),
      switchMap(() => this.userAuthService.logout()),
      map(() => AuthApisActions.authLogoutSuccess())
    )
  );

  onLogoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApisActions.authLogoutSuccess),
        tap(() =>
          this._authService.logout({
            logoutParams: { returnTo: environment.auth.logout_uri },
          })
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private userAuthService: UserAuthService,
    private store: Store<LoadingState>,
    private readonly _authService: AuthService
  ) {}
}
