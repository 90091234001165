import { createFeature, createReducer, on } from "@ngrx/store";
import { AuthApisActions } from "./actions";
import { authInitialState } from "./auth.state";
import authApisReducer from "./reducers/auth.reducer";

const AUTH_FEATURE_NAME = "auth";

export const authFeature = createFeature({
  name: AUTH_FEATURE_NAME,
  reducer: createReducer(
    authInitialState,
    on(AuthApisActions.authLoginSuccess, authApisReducer.authLoginSuccess),
    on(AuthApisActions.authLoginFailed, authApisReducer.authLoginFailed),
    on(AuthApisActions.authLogout, authApisReducer.authLogout),
    on(AuthApisActions.updateUser, authApisReducer.updateUser)
  ),
});

export const authSlice = {
  name: authFeature.name,
  reducer: authFeature.reducer,
};
