import { provideStoreDevtools } from "@ngrx/store-devtools";
import { AppEnvironment } from "./env.interface";

export const environment: AppEnvironment = {
  production: false,
  localStorageStoreName: window.location.host,
  apiBaseUrl: "https://dev-api.eateasy.io",
  business: {
    handleHost: "https://development.eateasy.io/menu/",
  },
  auth: {
    domain: "eateasy.eu.auth0.com",
    clientId: "4DSF1nsEyPcMgWV91AZetFOSWBvDgLMP",
    appUri: "https://dev-business.eateasy.io",
    errorPath: "/error",
    audience: "https://eateasy.eu.auth0.com/api/v2/",
    authorizationParams: {
      audience: "https://eateasy.eu.auth0.com/api/v2/",
      redirect_uri: "https://dev-business.eateasy.io/auth/login/callback",
    },
    logout_uri: "https://dev-business.eateasy.io/auth/logout/callback",
  },
  providers: [
    provideStoreDevtools({
      maxAge: 25,
      logOnly: false,
      autoPause: true,
      trace: false,
      traceLimit: 75,
      connectInZone: true,
    }),
  ],
  plausible: {
    src: "https://plausible.io/js/script.pageview-props.tagged-events.js",
    domain: "dev-business.eateasy.io",
  },
};
