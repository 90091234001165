import { GetLoginFailedPropsType, GetLoginSuccessPropsType } from "../actions";
import { AuthStateResponse } from "../auth.state";

const authLoginSuccess = (state: AuthStateResponse, response: GetLoginSuccessPropsType) => ({
  ...state,
  isLoggedIn: true,
  error: null,
  user: response?.user,
});

const authLoginFailed = (state: AuthStateResponse, err: GetLoginFailedPropsType) => ({
  ...state,
  isLoggedIn: false,
  error: err?.error,
  user: null,
});

const authLogout = (state: AuthStateResponse) => ({
  ...state,
  isLoggedIn: false,
  error: null,
  user: null,
});

const authLogoutSuccess = (state: AuthStateResponse) => ({
  ...state,
  isLoggedIn: false,
  error: null,
  user: null,
});

const updateUser = (state: AuthStateResponse, response: GetLoginSuccessPropsType) => ({
  ...state,
  user: { ...state.user, ...response.user },
});

export default {
  authLoginSuccess,
  authLoginFailed,
  authLogout,
  authLogoutSuccess,
  updateUser,
};
